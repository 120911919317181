<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">助学工具</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >备考资料</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div
                            title="备考资料名称"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">备考资料名称:</span>
                            <el-input
                                v-model="materialName"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入备考资料名称"
                            />
                        </div>
                        <div>
                            <el-button
                                class="bgc-bv"
                                @click="getData()"
                            >查询</el-button>
                        </div>
                    </div>
                    <div class="btnBox">
                        <el-button
                            class="bgc-bv"
                            @click="handleAdd()"
                        >新增备考资料</el-button>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="tableData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                            />
                            <el-table-column
                                label="备考资料名称"
                                align="center"
                                prop="materialName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="所属课程分类"
                                align="center"
                                prop="materialClassify"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="浏览量"
                                align="center"
                                prop="downloadNum"
                                width="140px"
                            />
                            <el-table-column
                                label="排序"
                                align="center"
                                prop="materialSort"
                                width="140px"
                            />
                            <el-table-column
                                label="状态"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <div class="flexca flexjc">
                                        <el-switch
                                            v-model="scope.row.materialState"
                                            active-value="10"
                                            inactive-value="20"
                                            @change="(val)=>{handleStu(val,scope.row)}"
                                        >
                                        </el-switch>
                                        <span style="margin-left:5px">{{scope.row.materialState == '10'?'上架':'下架'}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="创建时间"
                                align="center"
                                prop="createTime"
                            />
                            <el-table-column
                                label="操作"
                                align="center"
                                width="160px"
                            >
                                <div
                                    slot-scope="scope"
                                    class="flexcc"
                                >
                                    <el-button
                                        size="mini"
                                        type="text"
                                        @click="handleEdit(scope.row.materialId)"
                                    >编辑</el-button>
                                    <el-button
                                        size="mini"
                                        type="text"
                                        @click="handleDel(scope.row.materialId)"
                                    >删除</el-button>
                                </div>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="apiData"
                    @sizeChange="sizeChange"
                    @getData="getData"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "testDataList",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            materialName: "",
        };
    },
    computed: {},
    methods: {
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                materialName: this.materialName,
            };
            this.doFetch(
                {
                    url: "/biz/study-material/queryPage",
                    params,
                    pageNum,
                },
                true,
                2
            );
        },
        // 新增
        handleAdd() {
            this.$router.push({
                path: "/web/miniApp/testDataAdd",
            });
        },
        // 编辑
        handleEdit(materialId) {
            this.$router.push({
                path: "/web/miniApp/testDataAdd",
                query: {
                    materialId: materialId,
                },
            });
        },
        // 上 下 架
        handleStu(val, row) {
            this.$post(
                "/biz/study-material/updateMaterial",
                { materialId: row.materialId, materialState: val },
                3000,
                true,
                2
            )
                .then((res) => {
                    if (res.status == "0") {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 删除
        handleDel(materialId) {
            this.$confirm("删除后，将不可恢复", "确定删除吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$post(
                        "/biz/study-material/deleteMaterial",
                        { materialId },
                        3000,
                        true,
                        2
                    ).then((res) => {
                        if (res.status == "0") {
                            this.$messge({
                                type: "success",
                                messgae: res.message,
                            });
                            this.getData(-1);
                        }
                    });
                })
                .catch(() => {
                    return;
                });
        },
    },
};
</script>
<style lang="less"></style>
